@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutDeliveryOption {
    padding: 20px 0;
    border-top: 1px solid $color-neutral-30;

    &:last-of-type {
        border-bottom: 1px solid $color-neutral-30;
    }

    &::before {
        display: none;
    }

    &-Button {
        display: flex;
        width: 100%;
        align-items: center;
    }

    &-Row {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;

        svg {
            margin-right: 15px;
        }
    }

    &-Message {
        color: var(--primary-error-color);
    }

    &-IconWithName {
        display: flex;
        align-items: center;
    }

    &-Icon {
        width: 24px;
        margin-left: 24px;
        margin-right: 24px;
    }
}
