@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --checkout-step-title-color: #{$color-neutral-40};
    --checkout-step-count-background: #{$color-neutral-40};
    --checkout-step-count-color: #{$white};
    --checkout-step-separator-background: #{$black};
    --checkout-step-active-title-color: #{$black};
    --checkout-step-active-count-background: #{$black};
    --checkout-step-border-bottom: red;
}

.CheckoutSteps {
    counter-reset: checkoutSteps;
    display: flex;
    padding: 16px 0;
    border-bottom: 1px solid $color-neutral-30;

    &-Step {
        margin-right: 15px;

        @include mobile {
            margin-right: 0;
        }

        &,
        > a,
        button {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        > a span,
        > button span {
            cursor: pointer;
        }

        svg {
            margin-left: 10px;
            margin-right: 10px;
        }

        &_active {
            .CheckoutSteps-Step {
                &Count {
                    background: var(--checkout-step-active-count-background);
                }

                &Title {
                    color: var(--checkout-step-active-title-color);
                }
            }
        }

        &Count {
            counter-increment: checkoutSteps;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 48px;
            height: 48px;
            background: var(--checkout-step-count-background);
            margin-right: 16px;

            @include mobile {
                width: 36px;
                height: 36px;
            }

            &::after {
                content: counter(checkoutSteps);
                color: var(--checkout-step-count-color);
                font-weight: 500;
                font-size: 20px;

                @include mobile {
                    font-size: 12px;
                }
            }
        }

        &Title {
            font-size: 20px;
            font-weight: 500;
            color: var(--checkout-step-title-color);
            margin-right: 15px;
            cursor: default;

            @include mobile {
                margin-right: 0;
            }
        }
    }
}
