@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutSuccess {
    &-OrderNumber {
        padding: 0 0.75rem;
        color: $color-primary-50;
    }

    &-ProductsSection {
        margin-top: 8rem;
    }

    &-Products-Info {
        display: grid;
        grid-template-columns: 1fr 88px 88px 88px;
        grid-gap: 20px;
        padding: 0rem 2rem;
        border-bottom: 1px solid $color-neutral-30;
    }

    &-Products-Info p:first-of-type {
        text-align: left;
    }

    &-Products-Info p {
        text-align: center;
        color: $color-neutral-70;
    }

    &-Products-Info p:last-of-type {
        text-align: right;
    }

    > h3 {
        text-transform: initial;
        text-align: center;
        font-weight: 400;
        font-size: 24px;
        margin-bottom: 16px;
    }

    > p {
        text-align: center;
        color: $color-neutral-70;
        line-height: 24px;
        margin-bottom: 0;
        font-size: 16px;
    }

    &-PaymentRedirect {
        text-align: center;
        margin-top: 48px;
        margin-bottom: 30px;
    }

    &-PaymentRedirectText {
        font-size: 16px;
    }

    &-PaymentRedirectButton {
        text-align: center;
        margin-top: 32px;

        button {
            width: 277px;
        }
    }

    &-PaymentCAText {
        h3 {
            display: none;
        }

        b {
            font-size: 16px;
            font-weight: 400;
        }

        br {
            display: none;
        }
    }
}

@include mobile {
    .CheckoutSuccess {
        &-Products-InfoMobile {
            p {
                font-weight: 500;
                font-size: 2.5rem;
                border-bottom: 1px solid $color-neutral-30;
                padding-bottom: 3rem;
            }
        }

        &-Products-Info {
            display: none;
        }
    }
}

@include tabletAndAbove {
    .CheckoutSuccess {
        &-Products-InfoMobile {
            display: none;
        }

        >h3 {
            font-size: 2.5rem;
        }
    }
}

@include desktopAndAbove {
    .CheckoutSuccess {
        &-Products-Info {
            grid-template-columns: 1fr 115px 115px 115px;
        }
    }
}
