@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutSuccessSummary {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: auto;


    &-Box {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $color-neutral-30;
        align-items: center;
        padding-right: 2rem;

        span {
            color: $color-neutral-50;
        }
    }

    .BoxTotal {
        p {
            color: $color-primary-50;
            font-weight: 600;
        }
    }

    &-Box>div {
        display: flex;
        align-items: baseline;
        padding: 2rem;
    }

    &-Box p {
        margin-bottom: 0;
        margin-right: 5px;
    }
}


@include desktopAndAbove {
    .CheckoutSuccessSummary {
        width: 40%;
    }
}

@include above1200 {
    .CheckoutSuccessSummary {
        width: 30%;
    }
}
