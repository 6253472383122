@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutGuestForm {
    margin-bottom: 24px;

    form {
        > span {
            display: block;
            font-size: 14px;
            color: $color-neutral-50;
        }

        .MyAccountSignIn-Link {
            text-align: left;
        }

        .MyAccountSignIn-Button button {
            width: 100%;
        }
    }
}
