@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutOrderSummary {
    border: 1px solid $color-neutral-30;
    padding: 24px;
    border-radius: 0;
    margin-top: 30px;

    &-Header {
        display: block;

        .TypographyHeader {
            font-weight: 500;
            margin-bottom: 10px;
        }
    }

    &-ItemsInCart {
        margin-top: 4px;
        margin-bottom: 30px;
    }

    .CartItem {
        &:first-of-type {
            border-top: 1px solid var(--primary-divider-color);
        }

        &-Price {
            font-weight: 500;
            font-size: 14px;
        }

        &-Option {
            color: $black;
        }

        &-Quantity {
            color: $color-neutral-50;
        }
    }

    &-BackToCart {
        width: 100%;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        padding: 20px;

        a {
            color: $color-primary-50;
        }

        svg path {
            fill: $color-primary-50;
        }
    }

    &-OrderTotals {
        strong {
            font-weight: 400;
        }
    }

    &-TaxSubtitle {
        color: $color-neutral-50;
        display: flex;
        justify-content: flex-end;
        padding: 0;

        &::before {
            display: none;
        }
    }

    &-SummaryItem {
        &_isTotal {
            padding: 30px 0 0;
            justify-content: flex-end;
            align-items: baseline;

            p, strong {
                font-size: 24px;
                font-weight: 400;
                line-height: 32px;
            }

            p {
                margin-right: 10px;

                &::after {
                    content: ':';
                }
            }

            span {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                text-align: right;
                margin-top: 4px;
            }

            .CheckoutOrderSummary-Text {
                font-weight: 400;
                font-size: 24px;
            }
        }
    }

    &-Text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    &-ButtonWrapper {
        margin-top: 48px;
    }

    &-CouponCode {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.05em;
        color: $color-neutral-50;
        margin-top: 4px;
        text-transform: uppercase;
        text-align: left;
    }
}

.Checkout {
    .CheckoutOrderSummary {
        &-SummaryItem {
            border-bottom: 1px solid var(--secondary-base-color);
            padding: 23px 0;

            &_isTotal {
                border: none;
                padding-top: 32px;
                padding-bottom: 4px;
            }
        }
    }
}

@include mobile {
    .CheckoutOrderSummary {
        margin-top: 56px;

        &-Header {
            display: block;
        }

        &-ButtonWrapper {
            position: relative;
            border: 0;
            padding: 0;
            height: auto;
        }
    }
}
