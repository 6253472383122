@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutGuestForm {
    form {
        > span {
            display: block;
            font-size: 14px;
            color: $color-neutral-50;
            letter-spacing: 0.5px;
        }
    }
}

.CheckoutAddress {
    &Book {
        &-Wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 20px;
            align-items: start;
            margin-bottom: 40px;

            @include mobile {
                grid-template-columns: 1fr;
            }
        }

        &-CustomAddressWrapper {
            button {
                @include mobile {
                    width: 100%;
                }
            }
        }
    }
}
