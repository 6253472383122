@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --checkout-address-table-border: var(--secondary-base-color);
    --checkout-address-table-check-mark-hover-color: var(--secondary-dark-color);
    --checkout-address-selected-color: #{$color-primary-50};
    --checkout-address-table-check-mark-selected-color: var(--secondary-base-color);
}

.CheckoutAddressTable {
    width: 100%;
    height: 100%;

    &-Button {
        --check-mark-opacity: 0;

        border: 1px solid var(--checkout-address-table-border);
        transition-property: border-color, background, border-color;
        transition-duration: 150ms;
        transition-timing-function: ease-in;
        width: 100%;
        height: 100%;
        margin: 0;

        .KeyValueTable {
            border: none;
            margin-bottom: 0;
            height: 100%;

            &-Wrapper {
                height: 100%;
            }

            &-Heading {
                transition-property: background;
                transition-duration: 150ms;
                transition-timing-function: ease-in;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .RadioButtonIcon {
                    min-width: 15px;
                    display: inline-block;
                }
            }

            tbody {
                display: grid;
                grid-template-rows: repeat(4, 1fr);
                grid-column-gap: 0;
                grid-row-gap: 0;
                height: 100%;

                tr {
                    display: flex;
                    align-items: center;

                    th, td {
                        display: inline-block;
                        width: 50%;
                    }
                }
            }

            .Field-Wrapper_type_radio {
                display: inline-block;
            }
        }

        @include mobile {
            margin-block: 14px 0;
            margin-inline: 0 14px;
        }

        &:not(&_isSelected):hover {
            --check-mark-opacity: 1;
            --checkout-address-table-border: #{$color-neutral-40};
            --check-mark-color: var(--checkout-address-table-check-mark-hover-color);
        }

        &_isSelected {
            --check-mark-opacity: 1;
            --checkout-address-table-border: var(--checkout-address-selected-color);
            --check-mark-color: var(--checkout-address-table-check-mark-selected-color);

            .KeyValueTable-Heading {
                background: $color-primary-5;
            }
        }
    }

    &-SelectedLabel {
        color: var(--checkout-address-selected-color);
        text-transform: uppercase;

        &::before {
            content: ' - ';
            color: $black;
        }
    }
}
