@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutBilling {
    &-Comment {
        margin: 40px 0 0;

        textarea {
            border: 1px solid $color-neutral-30;
            width: 100%;
            padding: 30px;
            border-radius: 0;
        }
    }

    &-Heading {
        font-size: 30px;
        font-weight: 500;
        margin: 0 0 30px;
    }

    &-TACItem {
        margin-bottom: 20px;

        .Field {
            margin-bottom: 0;

            &-ErrorMessage {
                margin-left: 30px;
                margin-top: 10px;
            }
        }
    }

    &-TACItemOption {
        display: flex;
    }

    &-TermsAndConditions {
        margin-top: 50px;

        a {
            color: $color-primary-60;
            text-decoration: underline;

            &:hover {
                color: $color-primary-70;
            }
        }
    }

    &-TACLabel,
    &-CeneoOpineo,
    &-Newsletter {
        > * {
            display: inline;
            color: $color-neutral-70;
            font-size: 16px;
        }

        &_required {
            &::before {
                content: "*";
                color: $color-secondary-70;
                font-size: 16px;
                margin-right: 4px;
            }
        }
    }

    &-CeneoOpineo,
    &-Newsletter {
        display: flex;

        label {
            margin-top: 5px;
        }
    }

    &-CeneoOpineo {
        margin-bottom: 10px;
    }
}
