@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutPayment {
    border-top: 1px solid $color-neutral-30;
    padding: 14px 0;
    min-height: 70px;
    display: flex;
    align-items: center;

    &:last-of-type {
        border-bottom: 1px solid $color-neutral-30;
    }

    &::before {
        display: none;
    }

    &-Button {
        display: flex;
        align-items: center;
        font-size: 16px;
    }

    img {
        width: 38px;
        height: 38px;
        margin-right: 15px;
    }
}
