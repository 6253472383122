@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutAddressForm {
    .FieldForm-Fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        > .Field-Wrapper,
        > .FieldGroup-Wrapper_street {
            width: 48%;

            @include mobile {
                width: 100%;
            }
        }
    }

    .FieldGroup-Wrapper_address .FieldGroup {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        > * {
            width: 48%;

            @include mobile {
                width: 100%;
            }
        }
    }
}
