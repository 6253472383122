@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Checkout {
    &-Heading {
        font-size: 30px;
        line-height: 42px;
        font-weight: 500;
        margin-block-end: 32px;
    }

    &-Discount {
        margin-top: 24px;
    }

    &-Wrapper {
        &_isDetailsStep {
            grid-template-columns: repeat(1, 1fr);

            .Checkout-Header {
                justify-content: center;
                flex-wrap: wrap;
                margin-bottom: 48px;
                padding-bottom: 0;
            }

            .Checkout-Title {
                font-weight: 600;
                text-align: center;
                font-size: 30px;
            }

            .Checkout-IconWrapper {
                text-align: center;
                margin-bottom: 2rem;
                width: 100%;
            }
        }
    }
}

@include mobile {
    .Checkout {
        &-Wrapper {
            &_isDetailsStep {
                .Checkout-Title {
                    font-size: 2.5rem;
                }
            }
        }
    }
}

@include mobileAndTablet {
    .Checkout-Wrapper {
        display: block;
    }
}

