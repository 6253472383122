@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutSuccessDetails {
    &-Info {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        margin: 6rem 0 4rem;
    }

    &-InfoBox {
        padding: 32px;
        border: 1px solid $color-neutral-30;

        span {
            margin-right: 2px;
            font-size: 1.4rem;
        }
    }

    &-InfoBoxTitle {
        font-weight: 500;
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    &-InfoBoxContent {
        line-height: 28px;
    }
}


@include tabletAndAbove {
    .CheckoutSuccessDetails {
        &-Info {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@include desktopAndAbove {
    .CheckoutSuccessDetails {
        &-Info {
            grid-template-columns: 1fr 1fr;

            &_isTransfer {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }
    }
}
