@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutBilling {
    &-CeneoOpineoDescriptionTrigger {
        color: $color-primary-70;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
    }

    &-CeneoOpineoDescription {
        font-size: 14px;
        margin-top: 10px;
        display: none;

        &_isVisible {
            display: block;
        }
    }
}
